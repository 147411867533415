<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">档案配置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
          >{{ stu == "edit" ? "修改" : "新增" }}档案配置</a
          >
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexdc">
              <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="13rem"
              >
                <el-form-item label="行政区划" prop="areaId" class="form-item">
                  <el-input
                      v-if="Form.areaId == '-1000'"
                      v-model="areaName"
                      disabled
                      style="width: 250px"
                  />
                  <el-cascader
                      v-else
                      v-model="Form.areaId"
                      style="width: 250px"
                      :options="areatreeList"
                      :props="propsarea"
                      clearable
                      filterable
                      size="small"
                  ></el-cascader>
                </el-form-item>
                <el-form-item
                    label="培训类型"
                    prop="trainTypeId"
                    class="form-item"
                >
                  <el-input
                      v-if="Form.trainTypeId == '-1000'"
                      v-model="trainTypeName"
                      disabled
                      style="width: 250px"
                  />
                  <el-cascader
                      v-else
                      style="width: 250px"
                      size="small"
                      :props="{
                      emitPath: false,
                      value: 'id',
                      label: 'label',
                      children: 'children',
                      checkStrictly: true,
                    }"
                      v-model="Form.trainTypeId"
                      :options="trainTypeList"
                      clearable
                      @change="handleTrainType"
                  ></el-cascader>
                </el-form-item>
                <el-form-item
                    label="平台名称"
                    prop="platformName"
                    class="form-item"
                >
                  <el-input
                      v-model="Form.platformName"
                      disabled
                      size="small"
                      style="width: 250px"
                  />
                </el-form-item>
                <el-form-item
                    label="签章模版"
                    prop="signatureVersion"
                    class="form-item"
                >
                  <el-select
                      v-model="Form.signatureVersion"
                      style="width: 250px"
                      clearable
                      filterable
                      size="small"
                  >
                    <el-option
                        v-for="item in singnatureList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option
                    ></el-select>
                </el-form-item>
                <el-form-item
                    label="有效期"
                    prop="dateArr"
                    class="form-item"
                >
                  <el-date-picker
                    v-model="Form.dateArr"
                    type="daterange"
                    style="width: 250px!important"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="small"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-divider></el-divider>
              </el-form>
              <div
                  class="addlist"
                  style="display: flex; flex-wrap: wrap; padding: 0 7rem"
              >
                <div
                    class="df card-border form-box"
                    v-for="(item, index) in archivesList"
                    :key="index"
                >
                  <el-form
                      :model="item"
                      ref="cardform"
                      label-width="7rem"
                      class="form form-bg"
                  >
                    <el-form-item class="form-attribute">
                      <!-- <el-checkbox-group
                        v-model="Form1"
                        size="small"
                      > -->
                      <el-checkbox
                          v-model="item.checked"
                          @change="change"
                          :label="item.attributeCode"
                      >{{ item.attributeValue }}
                      </el-checkbox>
                      <!-- </el-checkbox-group> -->
                      <el-radio-group v-model="item.templateCode">
                        <el-radio
                            @change="changeradio"
                            v-for="(item1, index) in item.templateList"
                            :key="index"
                            :label="item1.templateCode"
                        >{{ item1.templateName }}
                          <el-button
                              type="text"
                              @click="looktemplate(item1.templateUrl)"
                          >查看模版</el-button
                          ></el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">{{
                  Form.trainTypeId != "-1000" && Form.areaId != "-1000"
                      ? "取消"
                      : "返回"
                }}</el-button>
              <el-button
                  @click="seveData('ruleForm')"
                  class="bgc-bv"
                  v-show="Form.trainTypeId != '-1000' && Form.areaId != '-1000'"
              >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="下载培训档案"
        :visible.sync="dialogVisible"
        width="50%"
        top="2%"
        center
        :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "SupervisionEnd/addClassSubject",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
          value &&
          !/^1[3456789]\d{9}$/.test(value) &&
          !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入负责人电话"));
      } else {
        callback();
      }
    };

    return {
      stu: "",
      areaName: "",
      trainTypeName: "",
      areatreeList: [],
      singnatureList: [], //签章模版
      editJson: {},
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        areaId: "",
        trainTypeId: "",
        platformName: "",
        signatureVersion: "10",
        dateArr:[],
      },
      trainTypeList: [], // 培训类型
      rules: {
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],

        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        platformName: [
          { required: true, message: "请输入平台名称", trigger: "blur" },
        ],
        signatureVersion: [
          { required: true, message: "请选择签章模版", trigger: "change" },
        ],
      },
      archivesList: [],
      dialogVisible: false,
    };
  },
  created() {

  },
  mounted() {
    this.getareatree();
    this.getTraintype();
    this.getdefaultList();
    this.id = this.$route.query.paraId;
    this.stu = this.$route.query.stu;
    if (this.$route.query.stu == "edit") {
      this.getDetail(this.$route.query.paraId);
    }else{
      this.getEntire()
    }
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    // 获取 - 班级状态
    getdefaultList() {
      const DEFAULT = this.$setDictionary("DEFAULT", "list");
      const singnature = this.$setDictionary("SIGNATURE_VERSIONS", "list");
      console.log(DEFAULT);
      const list = [];
      for (const key in singnature) {
        list.push({
          value: key,
          label: singnature[key],
        });
      }
      for (const key in DEFAULT) {
        if (key == "PLATFORM_NAME") this.Form.platformName = DEFAULT[key];
      }
      this.singnatureList = list;
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getEntire() {
      this.$post("/biz/archives/parameter/entire").then((ret) => {
        ret.data.map((el) => {
          el.checked = false;
          el.templateCode = "";
        });
        this.archivesList = ret.data || [];
        console.log(this.archivesList);
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      console.log(this.Form1);
      let paramr = {};
      if (this.Form.areaId) {
        paramr.areaId = this.Form.areaId;
      }
      if (this.Form.trainTypeId) {
        paramr.trainTypeId = this.Form.trainTypeId;
      }
      if (this.Form.platformName) {
        paramr.platformName = this.Form.platformName;
      }
      if (this.Form.signatureVersion) {
        paramr.signatureVersion = this.Form.signatureVersion;
      }
      if (this.Form.dateArr?.length===2) {
        paramr.expirationStartDate = this.Form.dateArr[0];
        paramr.expirationEndDate = this.Form.dateArr[1];
      }
      
      if (this.$route.query.paraId) {
        paramr.paraId = this.$route.query.paraId;
      }

      let attributeList = [];
      for (let i = 0; i < this.archivesList.length; i++) {
        console.log(this.archivesList[i]);
        if (this.archivesList[i].checked) {
          let obj = {
            attributeCode: this.archivesList[i].attributeCode,
            templateCode: this.archivesList[i].templateCode,
          };
          attributeList.push(obj);
        }
      }
      paramr.attributeList = attributeList;
      this.$post(
          this.$route.query.paraId
              ? "/biz/archives/parameter/modify"
              : "/biz/archives/parameter/insert",
          paramr
      )
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              setTimeout(() => {
                this.$router.push({
                  path: "/web/operate/fileSetupList",
                  query: {
                    refrsh: true,
                  },
                });
              }, 300);
            }
          })
          .catch((err) => {
            return;
          });
    },
    cancle() {
      this.$router.back();
    },
    getDetail(paraId) {
      this.$post("/biz/archives/parameter/entire").then((ret) => {
        ret.data.map((el) => {
          el.checked = false;
          el.templateCode = "";
        });
        this.archivesList = ret.data || [];
        this.$post("/biz/archives/parameter/getInfo", { paraId }).then((res) => {
          if (res.status == "0") {
            // console.log(this.archivesList);
            res.data.attributeList.map((el) => {
              this.archivesList.map((els) => {
                if (el.attributeCode == els.attributeCode) {
                  els.checked = true;
                  els.templateList.map((element) => {
                    console.log(element);
                    if (el.templateCode == element.templateCode) {
                      els.templateCode = element.templateCode;
                    }
                  });
                }
                // console.log(this.archivesList);
              });
            });

            this.Form = {
              ...res.data,
              dateArr:(res.data.expirationStartDate&&res.data.expirationEndDate)?[res.data.expirationStartDate.replaceAll('/','-'),res.data.expirationEndDate.replaceAll('/','-')]:''
            };
            this.areaName = "默认";
            this.trainTypeName = "默认";
          }
        });
      });

    },
    changeradio(val) {
      console.log(val);
    },
    looktemplate(url) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        pdf.embed(url, "#pdf-content", {
          pdfOpenParams: {
            scrollbars: "0",
            toolbar: "0",
            statusbar: "1",
          },
        });
      });
    },
  },
};
</script>
<style lang="less"></style>
<style lang="less">
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 100%;
      padding-left: 10rem;
    }
    .padd0 {
      padding-left: 0;
    }
    .form-attribute {
      width: 50%;
    }
  }
  .formAttributeBox {
    padding: 0 19rem;
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
  .paraCompleteConditionInput {
    display: inline-block;
    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}
.el-radio-group {
  display: flex;
  flex-direction: column;
  .el-radio {
    margin: 10px 0;
  }
}
.card-border {
  width: 50%;
}
</style>
